import React from 'react'
import { Link, graphql } from 'gatsby'

import SEO from '../components/seo'
import Bio from '../components/Bio'
import Layout from '../components/Layout'
import { rhythm } from '../utils/typography'
import GatsbyImage from 'gatsby-image'
import Zoom from 'react-reveal/Zoom'

// Style gatsby-image
import styled from 'styled-components'
const StyledImage = styled(GatsbyImage)`
  filter: brightness(1);
  transition-property: filter;
  transition-timing-function: linear;
  transition-duration: 0.25s;
  transition-delay: 0s;
  :hover {
    filter:brightness(1.5);
    transition-property: filter;
    transition-timing-function: linear;
    transition-duration: 0.25s;
    transition-delay: 0s;
  }
`

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>

        <SEO
          title="Home"
          keywords={[`index`, `blog`, `portfolio`, `3d`, `graphics`]}
        />
        <Bio />
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          // For image alt text, generated from graphQL query of the original name
          const altText = String(node.frontmatter.cover_image.childImageSharp.fluid.originalName)
          return (
            <div key={node.fields.slug}>

              {/* This is nested styling, ugly hacky... */}
              <h3
                style={{
                  marginBottom: rhythm(1 / 4),
                }}
              >
                {/* The link that is created for each page, this overrides the style in h3. */}
                <Link style={{ boxShadow: `none`, color: `#60e2b8` }} to={node.fields.slug}>
                  {title}
                </Link>
              </h3>
              {/*
              <small>{node.frontmatter.date}</small>
              */}
              <div style={{ paddingBottom: `5px` }}></div>
              <Link to={node.fields.slug}>
                <Zoom>
                  <StyledImage backgroundColor={ true } alt={ altText } style={{ outline: `2px solid #000000` }} fluid={node.frontmatter.cover_image.childImageSharp.fluid} />
                </Zoom>
              </Link>
              <p style={{ paddingTop: `10px` }} dangerouslySetInnerHTML={{ __html: node.excerpt }} />
            </div>
          )
        })}
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: {
        frontmatter: {published: {eq: true}, tags: { ne: "about" } } # Tried to avoid processing about page!
      }
      sort: { fields: [frontmatter___date], order: ASC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title

            cover_image {
              publicURL # creates url to the image, this is useful if we use GIFs, which gatsby image can't process.
                        # can be used to render with using normal <img> element.
              childImageSharp {
                fluid(maxWidth: 800, maxHeight: 150, cropFocus: CENTER, quality: 85) {
                  src
                  originalName
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }

          }
        }
      }
    }
  }
`
